import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GenericTable from "../Components/Table";
import {
  getUserListRequest,
  updateUserSubscriptionRequest,
  upgradeUserDataRequest,
  upgradeUserPremiumRequest,
} from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import defaultAvtar from "../assets/avatar.jpeg";
import Loader from "../Components/Loader";
import Error from "../Components/Error";
import moment from "moment";
import AlertDialog from "../Components/ActionDialog";
import fireAjax from "../services";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import SimpleSelect from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { Checkbox } from "@material-ui/core";

const CSVConverter = require("papaparse");

const list = [
  { label: "Free", value: "free" },
  { label: "Premium", value: "premium" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "&:-webkit-scrollbar": {
      width: 0,
      background: "transparent",
    },
    padding: 10,
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  searchTitle: {
    fontSize: "1rem",
  },
  searchFilter: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  dateFilter: {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
  },
  orderByFilter: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "flex-start",
    minWidth: 200,
  },
  orderByFilterSelect: {
    minWidth: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  playlistTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  totalRecords: {
    fontWeight: "bold",
    fontSize: "1rem",
  },

  newPlaylistTitle: {
    color: "#065fd4",
    fontWeight: "500",
    cursor: "pointer",
  },
  avtar: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
}));

export default function Users() {
  const [openDeleteUserListConfirmDialog, setOpenDeleteUserListConfirmDialog] =
    useState(false);
  const [
    openDowngradeUserListConfirmDialog,
    setOpenDowngradeUserListConfirmDialog,
  ] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [sortedUserData, setSortedUserData] = useState([]);
  const fileInputRef = useRef(null);
  const orderBy = useRef("");
  const searchInput = useRef("");
  // const createdAtFrom = useRef(moment().subtract(7, "d").format("YYYY-MM-DD"));
  const [createdAtFrom, setCreatedAtFrom] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [createdAtTo, setCreatedAtTo] = useState(moment().format("YYYY-MM-DD"));
  const sortOrder = useRef("asc");
  const subscriptionRefs = useRef([]);
  const [userSelectedList, setUserSelectedList] = useState([]);
  const userTableRef = useRef();

  const classes = useStyles();
  const dispatch = useDispatch();

  const userlist = useSelector((state) => state.course.userlist);

  // useEffect(() => {
  //     dispatch(getUserListRequest());
  // }, []);

  React.useMemo(() => {
    const sortedData =
      userlist.data &&
      Array.isArray(userlist.data) &&
      userlist.data?.sort((a, b) => {
        return 0;
      });
    setSortedUserData(sortedData);
  }, [userlist]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getSubType = (item) => {
    if (item.subscription === "free") {
      return "Free";
    } else {
      return item.sub_type ? capitalizeFirstLetter(item.sub_type) : "Premium";
    }
  };

  const getSubDate = (item) => {
    if (item.sub_type === "purchase") {
      if (item.paypal_subscription_id) {
        return "Paypal";
      } else {
        return "AppStore";
      }
    } else {
      return item.sub_end_date
        ? moment(item.sub_end_date).format("DD/MM/YYYY, kk:mm:ss")
        : null;
    }
  };

  const makeTableData = (rows = []) => {
    if (!rows.length) {
      return [];
    }
    let data = [];
    rows.map((item, index) => {
      let a = {
        profile: (
          <img
            alt="profile_image"
            src={item.profilePicture || defaultAvtar}
            className={classes.avtar}
          />
        ),
        name: item.name,
        profilePicture: item.profilePicture,
        _id: item._id,
        isChecked: userSelectedList.some((user) => user._id === item._id)
          ? true
          : false,
        // checkBox: checkBoxComponent(item),
        email: item.email,
        sub_type: getSubType(item),
        // subscription: editIconComponent(item, index + 1),
        createdAt: moment(item.createdAt).format("DD/MM/YYYY, kk:mm:ss"),
        isBookDownloaded: `${item.isBookDownloaded ? "Yes" : "No"}`,
        serialNumber: index + 1,
        sub_end_date: getSubDate(item),
      };
      data.push(a);
    });
    return data;
  };

  const onClickSearchButton = (evt) => {
    console.log("searchInput", searchInput.current.value);
    console.log("createdAtFrom", createdAtFrom);
    console.log("createdAtTo", createdAtTo);
    dispatch(
      getUserListRequest({
        data: {
          filter: searchInput.current.value,
          createdAtFrom: createdAtFrom,
          createdAtTo: createdAtTo,
          orderBy: orderBy.current.value,
          sortOrder: sortOrder.current.value,
        },
      })
    );
  };

  const handleChangeSubscription = (event, userId, index) => {
    if (event.target.value !== undefined) {
      console.log("event", event);
      const payload = { userId: userId, subscription: event.target.value };
      dispatch(updateUserSubscriptionRequest(payload));
      subscriptionRefs.current[index].value = event.target.value;
    }
  };

  // const editIconComponent = (data, index) => {
  //     return (
  //         <Select
  //             className="basic-single"
  //             value={list.filter(function(option) {
  //                 return option.value === data.subscription;
  //             })}
  //             onInputChange={(e) => handleChangeSubscription(e.value, data._id, index)}
  //             options={list}
  //             isSearchable={false}
  //             ref={(ref) => (subscriptionRefs.current[index] = ref)}
  //             isDisabled={false}
  //         />
  //     );
  // };

  const editIconComponent = (data, index) => {
    return (
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <SimpleSelect
          displayEmpty
          ref={(ref) => (subscriptionRefs.current[index] = ref)}
          onChange={(evt) => handleChangeSubscription(evt, data._id, index)}
          defaultValue={data.subscription}
        >
          <MenuItem value={"free"}>Free</MenuItem>
          <MenuItem value={"premium"}>Premium</MenuItem>
        </SimpleSelect>
      </FormControl>
    );
  };

  const handleFileUpload = (event) => {
    CSVConverter.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        let upgradeAddresses = [];
        results.data.forEach((value) => {
          let obj = {
            email: value.Email,
          };
          upgradeAddresses.push(obj);
        });
        dispatch(upgradeUserPremiumRequest(upgradeAddresses));
        dispatch(
          upgradeUserDataRequest({
            userList: sortedUserData,
            premiumEmail: upgradeAddresses,
          })
        );
        toast.success("Users Upgraded to Premium", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        event.target.value = null;
      },
    });
  };

  const handleDeleteAllUsersAction = (value) => {
    if (value.some((user) => user.sub_type.toLowerCase() === "purchase")) {
      toast.error("You can't delete paying users, please select again!!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(userTableRef);

      if (userTableRef.current) {
        userTableRef.current.changeItemSelectedList([]);
      }
      setUserSelectedList([]);
    } else {
      setUserSelectedList(value);
      setOpenDeleteUserListConfirmDialog(true);
    }
  };

  const onCloseDeleteUserListConfirmDialog = () => {
    setOpenDeleteUserListConfirmDialog(false);
  };

  const onSubmitDeleteUserListConfirmDialog = () => {
    setIsMounted(true);
    const apiResponse = fireAjax(
      "PATCH",
      "users/admin/remove-many-users",
      undefined,
      {
        userIds: userSelectedList.map((user) => user._id),
      }
    );
    apiResponse
      .then((response) => {
        dispatch(
          getUserListRequest({
            data: {
              filter: searchInput.current.value,
              orderBy: orderBy.current.value,
              sortOrder: sortOrder.current.value,
            },
          })
        );
        toast.success("Selected Users Deleted Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setOpenDeleteUserListConfirmDialog(false);
        setIsMounted(false);
        if (userTableRef.current) {
          userTableRef.current.changeItemSelectedList([]);
        }
        setUserSelectedList([]);
      });
  };

  const handleDowngradeAllUsersAction = (value) => {
    if (value.some((user) => user.sub_type.toLowerCase() === "purchase")) {
      toast.error("You can't downgrade paying users, please select again!!!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      if (userTableRef.current) {
        userTableRef.current.changeItemSelectedList([]);
      }
      setUserSelectedList([]);
    } else {
      setUserSelectedList(value);
      setOpenDowngradeUserListConfirmDialog(true);
    }
  };

  const onCloseDowngradeUserListConfirmDialog = () => {
    setOpenDowngradeUserListConfirmDialog(false);
  };

  const onSubmitDowngradeUserListConfirmDialog = () => {
    setIsMounted(true);

    const apiResponse = fireAjax("PATCH", "coupon/downgrade-many", undefined, {
      userIds: userSelectedList.map((user) => user._id),
    });
    apiResponse
      .then((response) => {
        dispatch(
          getUserListRequest({
            data: {
              filter: searchInput.current.value,
              orderBy: orderBy.current.value,
              sortOrder: sortOrder.current.value,
            },
          })
        );
        toast.success("Selected Users Downgraded Successfully", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.log("error", error.response.data.message);
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .finally(() => {
        setOpenDowngradeUserListConfirmDialog(false);
        setUserSelectedList([]);
        setIsMounted(false);
      });
  };

  return (
    <div className={classes.root}>
      <AlertDialog
        header={"Are you sure you want to delete the selected users?"}
        primaryText={"No"}
        secondaryText={"Yes"}
        onPrimaryBtnClick={onCloseDeleteUserListConfirmDialog}
        onSecondaryBtnClick={onSubmitDeleteUserListConfirmDialog}
        open={openDeleteUserListConfirmDialog}
        handleClose={onCloseDeleteUserListConfirmDialog}
      />
      <AlertDialog
        header={
          "Are you sure you want to downgrade the selected users to free?"
        }
        primaryText={"No"}
        secondaryText={"Yes"}
        onPrimaryBtnClick={onCloseDowngradeUserListConfirmDialog}
        onSecondaryBtnClick={onSubmitDowngradeUserListConfirmDialog}
        open={openDowngradeUserListConfirmDialog}
        handleClose={onCloseDowngradeUserListConfirmDialog}
      />
      {/* <div className={classes.titleWrapper}>
        <div className={classes.playlistTitle}>User List</div>
        {/* <input
          type="file"
          name="file"
          accept=".csv"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileUpload}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => fileInputRef.current.click()}
        >
          Upload CSV to upgrade to premium
        </Button>
      </div> */}
      <div className={classes.searchFilter}>
        <TextField
          id="standard-basic"
          label="Search by email address or name"
          fullWidth
          ref={searchInput}
          onChange={(evt) => (searchInput.current.value = evt.target.value)}
        />
        <div className={classes.dateFilter}>
          <TextField
            id="createdAtFromId"
            label="Created from"
            type="date"
            defaultValue={createdAtFrom}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => setCreatedAtFrom(evt.target.value)}
          />
          <TextField
            id="createdAtToId"
            label="Created till"
            type="date"
            defaultValue={createdAtTo}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => setCreatedAtTo(evt.target.value)}
          />
        </div>

        <div className={classes.orderByFilter}>
          <FormControl className={classes.orderByFilter}>
            <InputLabel id="order-by-label">Sort by</InputLabel>
            <SimpleSelect
              labelId="order-by-label"
              id="order-by-select"
              ref={orderBy}
              className={classes.orderByFilterSelect}
              onChange={(evt) => (orderBy.current.value = evt.target.value)}
            >
              <MenuItem value={"name"}>Name</MenuItem>
              <MenuItem value={"email"}>Email</MenuItem>
              <MenuItem value={"createdAt"}>Created date</MenuItem>
              <MenuItem value={"updatedAt"}>Modified date</MenuItem>
              <MenuItem value={"last_active_date"}>Last active date</MenuItem>
            </SimpleSelect>
          </FormControl>
          <FormControl className={classes.orderByFilter}>
            <InputLabel id="sort-order-label">Sort order</InputLabel>
            <SimpleSelect
              labelId="sort-order-label"
              id="sort-order-select"
              ref={sortOrder}
              className={classes.orderByFilterSelect}
              onChange={(evt) => (sortOrder.current.value = evt.target.value)}
            >
              <MenuItem value={"asc"}>Ascending</MenuItem>
              <MenuItem value={"desc"}>Descending</MenuItem>
            </SimpleSelect>
          </FormControl>
        </div>

        <Button
          variant="contained"
          color="secondary"
          onClick={onClickSearchButton}
        >
          Search
        </Button>
        <div className={classes.totalRecords}>
          Total records returned: {sortedUserData.length}
        </div>
      </div>
      {userlist.isLoading && !isMounted && <Loader variant="big" />}
      {((userlist.isSuccess && sortedUserData.length > 0) || isMounted) && (
        <GenericTable
          headers={[
            "S.No",
            // "Profile",
            "Name",
            "Email",
            // "Subscription",
            "Subscription Type",
            "Expires On",
            "Joined",
            "e-Book Downloaded",
          ]}
          cellData={makeTableData(sortedUserData)}
          keys={[
            "serialNumber",
            // "profile",
            "name",
            "email",
            // "subscription",
            "sub_type",
            "sub_end_date",
            "createdAt",
            "isBookDownloaded",
          ]}
          multipleSelect={true}
          deleteIcon={false}
          editIcon={false}
          // handleDelete={handleDelete}
          deleteAllAction={true}
          handleDeleteAllAction={handleDeleteAllUsersAction}
          downgradeAllAction={true}
          handleDowngradeAllAction={handleDowngradeAllUsersAction}
          ref={userTableRef}
        />
      )}
      {userlist.isError && <Error message={userlist.message} />}
    </div>
  );
}
