import React, {useEffect, useState} from 'react'
import '../styles/login.scss'
import {Button} from "@material-ui/core";

export default function ChangePassword({onSubmit}) {

    const [state, setState] = useState({
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
        validationRules: {
            number: {
                val: false,
            },
            specialChar: {
                val: false,
            },
            uppercase: {
                val: false,
            },
            lowercase: {
                val: false,
            },
            length: {
                val: false,
            },
            passwordMatch: {
                val: false,
            },
        },
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit(state.currentPassword, state.newPassword, state.newPasswordConfirmation);
    }

    const handleChange = (value, name) => {
        let data = {...state}
        data[name] = value
        setState(data)
    }

    useEffect(() => {
        validatePassword(state)
    }, [state.newPassword, state.newPasswordConfirmation])

    const isSubmitDisabled = () => {
        let isDisabled = false
        if (state.currentPassword === '' || state.newPassword === '' || state.newPasswordConfirmation === '') {
            isDisabled = true
        }

        Object.entries(state.validationRules).forEach((item, key) => {
            if (item[1].val === false) {
                isDisabled = true;
            }
        })

        return isDisabled;
    };

    const validatePassword = (state) => {
        const {newPassword, newPasswordConfirmation} = state;
        const validationRules = {
            number: {
                val: /\d/.test(newPassword),
                label: "Contains at least 1 number"
            },
            specialChar: {
                val: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
                label: "Contains at least 1 special character"
            },
            uppercase: {
                val: /[A-Z]/.test(newPassword),
                label: "Contains at least 1 uppercase letter"
            },
            lowercase: {
                val: /[a-z]/.test(newPassword),
                label: "Contains at least 1 lowercase letter"
            },
            length: {
                val: newPassword.length >= 12,
                label: "Password minimum length is 12 characters"
            },
            passwordMatch: {
                val: newPassword === newPasswordConfirmation,
                label: "Password  confirmation must match"
            },
        };

        // Update error state and return whether all rules are satisfied
        setState((prev) => ({...prev, validationRules}));
        return Object.values(validationRules).every((rule) => rule);
    };

    return (
        <div className="wrapper fadeInDown">

            <div id="formContent">
                <h2 className="active"> Change password </h2>

                <form contentEditable={false}>
                    <input onChange={(e) => handleChange(e.target.value, 'currentPassword')} type="password"
                           id="currentPassword" className="fadeIn first" name="currentPassword"
                           placeholder="Current password" required/>
                    <input onChange={(e) => handleChange(e.target.value, 'newPassword')} type="password"
                           id="newPassword" className="fadeIn first" name="newPassword" placeholder="New password"
                           required/>
                    <input onChange={(e) => handleChange(e.target.value, 'newPasswordConfirmation')} type="password"
                           id="newPasswordConfirmation" className="fadeIn first" name="newPasswordConfirmation"
                           placeholder="Confirm new password" required/>
                    <div style={{textAlign: 'left', marginLeft: '3rem', marginTop: 16}} className="fadeIn first">
                        {Object.entries(state.validationRules).map(([key, item]) => (
                            <div key={key} style={{color: item.val ? 'green' : 'red', fontSize: 15}}>
                                {item.label}
                            </div>
                        ))}
                    </div>
                    <Button
                        className="fadeIn second"
                        style={{marginBottom: 24, marginTop: 24}}
                        variant="contained"
                        color="primary"
                        disabled={isSubmitDisabled()}
                        onClick={handleSubmit}
                        type="submit"
                    >Submit</Button>
                </form>

            </div>
        </div>
    )
}
