import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";

const initialState = {
  getBooks: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },

  postBooks: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },

  putBooks: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },

  deleteBooks: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    data: {},
    message: "",
  },
};

const getBooksRequest = (state, action) => {
  return update(state, {
    getBooks: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};
const getBooksSuccess = (state, action) => {
  return update(state, {
    getBooks: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};
const getBooksError = (state, action) => {
  return update(state, {
    getBooks: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

const postBooksRequest = (state, action) => {
  return update(state, {
    postBooks: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};
const postBooksSuccess = (state, action) => {
  return update(state, {
    postBooks: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};
const postBooksError = (state, action) => {
  return update(state, {
    postBooks: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

const putBookRequest = (state, action) => {
  return update(state, {
    putBooks: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};
const putBookSuccess = (state, action) => {
  return update(state, {
    putBooks: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};
const putBookError = (state, action) => {
  return update(state, {
    putBooks: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

const deleteBookRequest = (state, action) => {
  return update(state, {
    deleteBooks: {
      isLoading: { $set: true },
      isError: { $set: false },
      isSuccess: { $set: false },
      message: { $set: "" },
      data: { $set: {} },
    },
  });
};
const deleteBookSuccess = (state, action) => {
  return update(state, {
    deleteBooks: {
      isLoading: { $set: false },
      isError: { $set: false },
      isSuccess: { $set: true },
      message: { $set: "" },
      data: { $set: action.payload },
    },
  });
};
const deleteBookError = (state, action) => {
  return update(state, {
    deleteBooks: {
      isLoading: { $set: false },
      isError: { $set: true },
      isSuccess: { $set: false },
      message: { $set: action.payload.message },
      data: { $set: {} },
    },
  });
};

export default handleActions(
  {
    [constants.GET_BOOKS_REQUEST]: getBooksRequest,
    [constants.GET_BOOKS_SUCCESS]: getBooksSuccess,
    [constants.GET_BOOKS_ERROR]: getBooksError,
    [constants.POST_BOOKS_REQUEST]: postBooksRequest,
    [constants.POST_BOOKS_SUCCESS]: postBooksSuccess,
    [constants.POST_BOOKS_ERROR]: postBooksError,
    [constants.PUT_BOOK_REQUEST]: putBookRequest,
    [constants.PUT_BOOK_SUCCESS]: putBookSuccess,
    [constants.PUT_BOOK_ERROR]: putBookError,
    [constants.DELETE_BOOK_REQUEST]: deleteBookRequest,
    [constants.DELETE_BOOK_SUCCESS]: deleteBookSuccess,
    [constants.DELETE_BOOK_ERROR]: deleteBookError,
  },
  initialState
);
