import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GenericTable from "../Components/Table";
import {
  getUserListRequest,
  updateUserSubscriptionRequest,
  createCouponRequest,
  getCouponListRequest,
} from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import defaultAvtar from "../assets/avatar.jpeg";
import Loader from "../Components/Loader";
import Error from "../Components/Error";
import moment from "moment";
import AlertDialog from "../Components/ActionDialog";
import fireAjax from "../services";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import SimpleSelect from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MultipleSelect from "../Components/Select";
import { AppBar, Tab, TableCell, Tabs } from "@material-ui/core";
import GrantPremiumModal from "../Components/GrantPremiumModal";
import usePrevious from "../hooks/usePrevious";

const CSVConverter = require("papaparse");

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "&:-webkit-scrollbar": {
      width: 0,
      background: "transparent",
    },
    padding: 10,
  },
  tabContainer: {
    justifyContent: "space-between",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  searchTitle: {
    fontSize: "1rem",
  },
  couponForm: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  dateRange: {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    marginTop: 10,
    marginBottom: 10,
  },
  searchFilter: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  dateFilter: {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
  },
  orderByFilter: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "flex-start",
    minWidth: 200,
  },
  orderByFilterSelect: {
    minWidth: 200,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  couponTitle: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  totalRecords: {
    fontWeight: "bold",
    fontSize: "1rem",
  },
  newCoupon: {
    fontWeight: "bold",
    fontSize: "1rem",
    marginTop: 16,
  },
  newPlaylistTitle: {
    color: "#065fd4",
    fontWeight: "500",
    cursor: "pointer",
  },
  avtar: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
    "&:hover": {
      background: "#3ea8ad",
    },
  },
  label: {
    marginTop: 10,
  },
  btnIcon: {
    cursor: "pointer",
  },
}));

export default function Coupon() {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openGrantPremiumDialog, setOpenGrantPremiumDialog] = useState(false);

  const validFrom = useRef(moment(new Date()).format("YYYY-MM-DD"));
  const validTo = useRef(moment(new Date()).format("YYYY-MM-DD"));
  const quantityCoupon = useRef("");
  const validDuration = useRef("");
  const [couponData, setCouponData] = useState([]);

  const [activeCouponId, setActiveCouponId] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [sortedUserData, setSortedUserData] = useState([]);
  const orderBy = useRef("");
  const searchInput = useRef("");
  const sortOrder = useRef("asc");
  const createdAtFrom = useRef(moment(new Date()).format("YYYY-MM-DD"));
  const createdAtTo = useRef(moment(new Date()).format("YYYY-MM-DD"));

  const [userSelectedList, setUserSelectedList] = useState([]);
  const [searchData, setSearchData] = useState(null);

  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const userlist = useSelector((state) => state.course.userlist);
  const coupons = useSelector((state) => state.course.coupons);
  const newCoupon = useSelector((state) => state.course.newCoupon);
  const grantPremium = useSelector((state) => state.course.grantPremium);

  const previousGrantPremiumLoading = usePrevious(grantPremium.isLoading);
  useEffect(() => {
    dispatch(getCouponListRequest());
  }, []);

  React.useMemo(() => {
    const freeUsers =
      userlist.data &&
      Array.isArray(userlist.data) &&
      userlist.data?.filter(
        (user) => user.subscription === "free" || user.sub_type === "freemium"
      );
    const sortedData =
      freeUsers &&
      Array.isArray(freeUsers) &&
      freeUsers?.sort((a, b) => {
        return 0;
      });
    setSortedUserData(sortedData);
  }, [userlist]);

  React.useMemo(() => {
    setCouponData(coupons.data || []);
  }, [coupons]);

  const getDurationLabel = (value) => {
    if (+value === 1) {
      return "1 month";
    } else if (+value === 3) {
      return "3 months";
    } else {
      return "12 months";
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const makeCouponTableData = (rows = []) => {
    if (!rows.length) {
      return [];
    }
    let data = [];
    rows.map((item, index) => {
      let a = {
        _id: item._id,
        code: item.code,
        startDate: moment(item.start_date).format("DD/MM/YYYY"),
        endDate: moment(item.end_date).format("DD/MM/YYYY"),
        quantity: item.quantity,
        duration: getDurationLabel(item.duration),
        serialNumber: index + 1,
      };
      data.push(a);
    });
    return data;
  };
  const makeUserTableData = (rows = []) => {
    if (!rows.length) {
      return [];
    }
    let data = [];
    rows.map((item, index) => {
      let a = {
        _id: item._id,
        name: item.name,
        email: item.email,
        sub_type: item.sub_type
          ? capitalizeFirstLetter(item.sub_type)
          : capitalizeFirstLetter(item.subscription),
        serialNumber: index + 1,
        createdAt: moment(item.createdAt).format("DD/MM/YYYY, kk:mm:ss"),
        endOfSubscription: "NA",
        isChecked: userSelectedList.some((user) => user._id === item._id)
          ? true
          : false,
      };
      data.push(a);
    });
    return data;
  };

  const onClickSaveButton = (evt) => {
    const payload = {
      quantity: +quantityCoupon.current.value,
      start_date: validFrom.current.value,
      end_date: validTo.current.value,
      duration: validDuration.current.value || 1,
    };
    dispatch(createCouponRequest(payload));
  };

  const handleDelete = (e) => {
    setActiveCouponId(e);
    setOpenDeleteDialog(true);
  };

  const onCloseDeleleDialogClick = () => {
    setOpenDeleteDialog(false);
  };

  const onSubmitDeleteDialogClick = () => {
    setIsMounted(true);
    fireAjax("DELETE", `coupon/${activeCouponId}`).then(() => {
      dispatch(getCouponListRequest());
      setOpenDeleteDialog(false);
      toast.success("Coupon Deleted Successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  const onClickSearchButton = (evt) => {
    console.log("searchInput", searchInput.current.value);
    console.log("createdAtFrom", createdAtFrom.current.value);
    console.log("createdAtTo", createdAtTo.current.value);
    dispatch(
      getUserListRequest({
        data: {
          filter: searchInput.current.value,
          createdAtFrom: createdAtFrom.current.value,
          createdAtTo: createdAtTo.current.value,
          orderBy: orderBy.current.value,
          sortOrder: sortOrder.current.value,
        },
      })
    );
  };
  const handleUpgradeAllUsersAction = (value) => {
    setUserSelectedList(value);
    setSearchData({
      filter: searchInput.current.value,
      createdAtFrom: createdAtFrom.current.value,
      createdAtTo: createdAtTo.current.value,
      orderBy: orderBy.current.value,
      sortOrder: sortOrder.current.value,
    });
    setOpenGrantPremiumDialog(true);
  };

  const onCloseGrantPremiumDialogClick = () => {
    setOpenGrantPremiumDialog(false);
  };

  React.useMemo(() => {
    if (previousGrantPremiumLoading && !grantPremium.isLoading) {
      setUserSelectedList([]);
    }
  }, [grantPremium, previousGrantPremiumLoading]);

  return (
    <div className={classes.root}>
      <AlertDialog
        header={"Are you sure you want to delete this coupon?"}
        primaryText={"No"}
        secondaryText={"Yes"}
        onPrimaryBtnClick={onCloseDeleleDialogClick}
        onSecondaryBtnClick={onSubmitDeleteDialogClick}
        open={openDeleteDialog}
        handleClose={onCloseDeleleDialogClick}
      />
      <GrantPremiumModal
        open={openGrantPremiumDialog}
        handleClose={onCloseGrantPremiumDialogClick}
        data={userSelectedList || []}
        searchData={searchData || null}
      />
      <div className={classes.titleWrapper}>
        <div className={classes.couponTitle}>Coupon</div>
      </div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleTabChange}
          className={classes.tabContainer}
        >
          <Tab label="Coupon List" {...a11yProps(0)} />

          <Tab label="Create Coupon" {...a11yProps(1)} />
          <Tab label="Grant Premium" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <>
          <h3>Coupon List</h3>
          {coupons.isLoading && !isMounted && <Loader variant="big" />}
          {((coupons.isSuccess && couponData.length > 0) || isMounted) && (
            <GenericTable
              headers={[
                "S.No",
                "Code",
                "Start date",
                "End date",
                "Quantity",
                "Duration",
              ]}
              cellData={makeCouponTableData(couponData)}
              keys={[
                "serialNumber",
                "code",
                "startDate",
                "endDate",
                "quantity",
                "duration",
              ]}
              deleteIcon={true}
              editIcon={false}
              updateIcon={false}
              handleDelete={handleDelete}
            />
          )}
          {coupons.isError && <Error message={coupons.message} />}
        </>
      )}
      {value === 1 && (
        <div className={classes.paperView}>
          <h3>Create Coupon</h3>
          <div className={classes.couponForm}>
            <div className={classes.label}>Quantity</div>
            <input
              onChange={(evt) =>
                (quantityCoupon.current.value = evt.target.value || null)
              }
              type="number"
              id="quantity"
              ref={quantityCoupon}
              name="quantityCoupon"
              placeholder="Quantity"
              defaultValue="1"
              required
            />

            <div className={classes.label}>Duration</div>
            <SimpleSelect
              labelId="durationLabel"
              id="durationId"
              ref={validDuration}
              defaultValue={"1"}
              className={classes.orderByFilterSelect}
              onChange={(evt) => {
                validDuration.current.value = evt.target.value || null;
              }}
            >
              <MenuItem value={"1"}>1 month</MenuItem>
              <MenuItem value={"3"}>3 months</MenuItem>
              <MenuItem value={"12"}>12 months</MenuItem>
            </SimpleSelect>
            <div className={classes.dateRange}>
              <TextField
                id="validFromId"
                label="Valid from"
                type="date"
                ref={validFrom}
                // defaultValue={validFrom.current.value || null}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) =>
                  (validFrom.current.value = evt.target.value || null)
                }
              />

              <TextField
                id="validToId"
                label="Valid till"
                type="date"
                ref={validTo}
                // defaultValue={validTo.current.value || null}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) =>
                  (validTo.current.value = evt.target.value || null)
                }
              />
            </div>

            <Button
              variant="contained"
              color="secondary"
              onClick={onClickSaveButton}
            >
              Save
            </Button>
            <div className={classes.newCoupon}>
              New Coupon: {newCoupon.data.code}
            </div>
          </div>
        </div>
      )}

      {value === 2 && (
        <>
          <h3>Grant Premium</h3>
          <div className={classes.searchFilter}>
            <TextField
              id="standard-basic"
              label="Search by email address or name"
              fullWidth
              ref={searchInput}
              onChange={(evt) => (searchInput.current.value = evt.target.value)}
            />
            <div className={classes.dateFilter}>
              <TextField
                id="createdAtFromId"
                label="Created from"
                type="date"
                ref={createdAtFrom}
                // defaultValue={createdAtFrom.current.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) =>
                  (createdAtFrom.current.value = evt.target.value)
                }
              />
              <TextField
                id="createdAtToId"
                label="Created till"
                type="date"
                ref={createdAtTo}
                // defaultValue={createdAtTo.current.value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) =>
                  (createdAtTo.current.value = evt.target.value)
                }
              />
            </div>

            <div className={classes.orderByFilter}>
              <FormControl className={classes.orderByFilter}>
                <InputLabel id="order-by-label">Sort by</InputLabel>
                <SimpleSelect
                  labelId="order-by-label"
                  id="order-by-select"
                  ref={orderBy}
                  className={classes.orderByFilterSelect}
                  onChange={(evt) => (orderBy.current.value = evt.target.value)}
                >
                  <MenuItem value={"name"}>Name</MenuItem>
                  <MenuItem value={"email"}>Email</MenuItem>
                  <MenuItem value={"createdAt"}>Created date</MenuItem>
                  <MenuItem value={"updatedAt"}>Modified date</MenuItem>
                  <MenuItem value={"last_active_date"}>
                    Last active date
                  </MenuItem>
                </SimpleSelect>
              </FormControl>
              <FormControl className={classes.orderByFilter}>
                <InputLabel id="sort-order-label">Sort order</InputLabel>
                <SimpleSelect
                  labelId="sort-order-label"
                  id="sort-order-select"
                  ref={sortOrder}
                  className={classes.orderByFilterSelect}
                  onChange={(evt) =>
                    (sortOrder.current.value = evt.target.value)
                  }
                >
                  <MenuItem value={"asc"}>Ascending</MenuItem>
                  <MenuItem value={"desc"}>Descending</MenuItem>
                </SimpleSelect>
              </FormControl>
            </div>

            <Button
              variant="contained"
              color="secondary"
              onClick={onClickSearchButton}
            >
              Search
            </Button>
            <div className={classes.totalRecords}>
              Total records returned: {sortedUserData.length}
            </div>
          </div>
          {userlist.isLoading && !isMounted && <Loader variant="big" />}
          {((userlist.isSuccess && sortedUserData.length > 0) || isMounted) && (
            <GenericTable
              headers={[
                "S.No",
                "Name",
                "Email",
                "Subscription Type",
                "Joined",
                "End Of Subscription",
              ]}
              cellData={makeUserTableData(sortedUserData)}
              keys={[
                "serialNumber",
                "name",
                "email",
                "sub_type",
                "createdAt",
                "endOfSubscription",
              ]}
              deleteIcon={false}
              editIcon={false}
              multipleSelect={true}
              upgradeAllAction={true}
              handleUpgradeAllAction={handleUpgradeAllUsersAction}
            />
          )}
          {userlist.isError && <Error message={userlist.message} />}
        </>
      )}

      {userlist.isLoading && !isMounted && <Loader variant="big" />}
      {userlist.isError && <Error message={userlist.message} />}
    </div>
  );
}
