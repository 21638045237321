import {combineReducers} from "redux";
import auth from './auth/reducer';
import course from './course/reducer';
import books from "./books/reducer";
import systems from "./system/reducer";
import config from './config/reducer';
import {connectRouter} from "connected-react-router";

export default (history) => combineReducers({
    systems,
    auth,
    course,
    config,
    books,
    router: connectRouter(history)
});
