import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { Avatar, Button, Modal, Paper, TextField } from "@material-ui/core";
import { Backdrop, Fade } from "@material-ui/core";

const UploadPdfModal = (props) => {
  const {
    showUploadPDFModal,
    handleToggleModal,
    uploadedBookData,
    handleFileDrop,
    handleFileInputChange,
    handleFileImageUpload,
    handleBookUpload,
    setUploadedBookData,
  } = props;

  const classes = useStyles();

  const fileUploadRef = React.useRef(null);
  const uploadImagedRef = React.useRef(null);
  return (
    <Modal
      className={classes.modal}
      open={showUploadPDFModal}
      onClose={handleToggleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showUploadPDFModal}>
        <div className={classes.paper}>
          {uploadedBookData.url && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <img
                src={uploadedBookData.url}
                alt="EBook"
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: 12,
                }}
              />
            </div>
          )}
          <TextField
            label="Title"
            variant="outlined"
            value={uploadedBookData.title}
            placeholder="Enter title"
            onChange={(e) =>
              setUploadedBookData({
                ...uploadedBookData,
                title: e.target.value,
              })
            }
            className={classes.titleField}
            style={{
              marginTop: 10,
            }}
          />
          <TextField
            label="Enter Description"
            variant="outlined"
            value={uploadedBookData.description}
            placeholder="Enter Description"
            minRows={4}
            onChange={(e) =>
              setUploadedBookData({
                ...uploadedBookData,
                description: e.target.value,
              })
            }
            fullWidth
            className={classes.titleField}
          />
          <div
            className={classes.dropZone}
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            {uploadedBookData.book ? (
              <div>
                <p>Uploaded File: {uploadedBookData.book.name}</p>
              </div>
            ) : (
              <div className={classes.uploadZone}>
                <CloudUploadIcon className={classes.icon} />
                <p className={classes.uploadText}>Drag and Drop here</p>
                <p className={classes.uploadText}>or</p>
                <input
                  type="file"
                  accept=".pdf"
                  style={{ display: "none" }}
                  onChange={handleFileInputChange}
                  ref={fileUploadRef}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploadButton}
                  onClick={() => fileUploadRef.current.click()}
                >
                  Browse File
                </Button>
              </div>
            )}
          </div>
          <div className={classes.imageContainer}>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileImageUpload}
              ref={uploadImagedRef}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.uploadButton}
            onClick={() => uploadImagedRef.current.click()}
            style={{ marginBottom: "10px" }}
          >
            Upload Image
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.uploadButton}
            onClick={handleBookUpload}
            disabled={
              !uploadedBookData.url ||
              uploadedBookData.title.length < 1 ||
              uploadedBookData.description.length < 1 ||
              uploadedBookData.book === null
            }
          >
            Upload
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "40vw",
    borderRadius: 5,
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
    padding: 12,
  },
  titleField: {
    width: "100%",
    marginBottom: 10,
  },
  dropZone: {
    width: "100%",
    minHeight: "30vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #A3A6C7",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      border: "2px dashed #2196f3",
    },
  },
  icon: {
    fontSize: "70px",
    color: "#A3A6C7",
  },
  uploadZone: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  uploadText: {
    margin: 2,
    fontSize: 18,
  },
  uploadButton: {
    fontSize: 14,
    fontWeight: "600",
    opacity: 0.8,
    backgroundColor: "#0010FF",
    color: "white",
    borderRadius: "8px",
    padding: "10px",
    textTransform: "capitalize",
  },
  imageContainer: {
    marginTop: 20,
  },
  imagePaper: {
    padding: theme.spacing(2),
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  image: {
    maxWidth: "50%",
    maxHeight: "30%",
  },
}));

export default UploadPdfModal;
