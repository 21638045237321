import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import appLogo from '../assets/logo.png'
import { loginRequest } from '../redux/actions'
import validator from '../utils/validation'
import '../styles/login.scss'
import Loader from '../Components/Loader'
import {Link} from "@material-ui/core";

export default function Login() {

    const dispatch = useDispatch()
    const history = useHistory();
    const loginData = useSelector(state => state.auth.loginData)
    const [state, setState] = useState({ 
        email: '',
        password: '',
        errors: {}
     })

    const handleSubmit = (e) => {
        e.preventDefault()
        const errors = validator(state)
        setState({ ...state, errors })
        if(Object.keys(errors).length){
            console.log("form validation error")
        }else{
            const payload = { email: state.email, password: state.password }
            dispatch(loginRequest(payload))
        }
    }

    const handleChange = (value, name) => {
        let data = { ...state }
        data[name] = value
        setState(data)
    }

    const forgotPasswordHandler = (event) => {
        event.preventDefault();
        history.push("/forgotPassword")
    }
    
    return (
        <div className="wrapper fadeInDown">
           
            <div id="formContent">
                <h2 className="active"> Sign In </h2>
                {/* <h2 className="inactive underlineHover">Sign Up </h2> */}

                <div className="fadeIn first" style={{marginTop: 16, marginBottom: 16}}>
                    <img src={appLogo} id="icon" alt="User Icon" />
                </div>

                <form contentEditable={false} onSubmit={handleSubmit}>
                    <input onChange={(e) => handleChange(e.target.value, 'email')} type="text" id="login" className="fadeIn second" name="login" placeholder="email / username" required/>
                    <input onChange={(e) => handleChange(e.target.value, 'password')} type="password" id="password" className="fadeIn third" name="login" placeholder="password" required/>
                    <div style={{ color: 'red', textAlign: 'left', marginLeft: '3rem', fontSize: 15, marginBottom: 10 }} >{loginData.message}</div>
                    {loginData.isLoading && <div className='loader-wrapper'><Loader /></div>}
                    <Link href="#" style={{display: "block", marginBottom: 16}} onClick={forgotPasswordHandler}>
                        Forgot password
                    </Link>
                    <input type="submit" className="fadeIn fourth" value="Log In"/>
                </form>

                <div id="formFooter">
                {/* <a className="underlineHover" href="#">Forgot Password?</a> */}
                </div>

            </div>
        </div>
    )
}
