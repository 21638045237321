export const SYSTEM_DATA_LOADING_REQUEST = "SYSTEM_DATA_LOADING_REQUEST";
export const SYSTEM_DATA_LOADED_SUCCESS = "SYSTEM_DATA_LOADED_SUCCESS";
export const SYSTEM_DATA_LOADED_ERROR = "SYSTEM_DATA_LOADED_ERROR";

export const MANUAL_AUTH_REQUEST = "MANUAL_AUTH_REQUEST";
export const MANUAL_AUTH_SUCCESS = "MANUAL_AUTH_SUCCESS";
export const MANUAL_AUTH_ERROR = "MANUAL_AUTH_ERROR";

export const SOCIAL_AUTH_REQUEST = "SOCIAL_AUTH_REQUEST";
export const SOCIAL_AUTH_SUCCESS = "SOCIAL_AUTH_SUCCESS";
export const SOCIAL_AUTH_ERROR = "SOCIAL_AUTH_ERROR";

export const CREATE_TRAINER_REQUEST = "CREATE_TRAINER_REQUEST";
export const CREATE_TRAINER_SUCCESS = "CREATE_TRAINER_SUCCESS";
export const CREATE_TRAINER_ERROR = "CREATE_TRAINER_ERROR";

export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_ERROR = "CREATE_COURSE_ERROR";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_ERROR = "UPDATE_COURSE_ERROR";

export const UPDATE_TRAINER_REQUEST = "UPDATE_TRAINER_REQUEST";
export const UPDATE_TRAINER_SUCCESS = "UPDATE_TRAINER_SUCCESS";
export const UPDATE_TRAINER_ERROR = "UPDATE_TRAINER_ERROR";

export const GET_COURSELIST_REQUEST = "GET_COURSELIST_REQUEST";
export const GET_COURSELIST_SUCCESS = "GET_COURSELIST_SUCCESS";
export const GET_COURSELIST_ERROR = "GET_COURSELIST_ERROR";

export const GET_TRAINERS_REQUEST = "GET_TRAINERS_REQUEST";
export const GET_TRAINERS_SUCCESS = "GET_TRAINERS_SUCCESS";
export const GET_TRAINERS_ERROR = "GET_TRAINERS_ERROR";

export const UPLOAD_VIDEO_REQUEST = "UPLOAD_VIDEO_REQUEST";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_ERROR = "UPLOAD_VIDEO_ERROR";

export const GET_VIDEO_REQUEST = "GET_VIDEO_REQUEST";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const GET_VIDEO_ERROR = "GET_VIDEO_ERROR";

export const DELETE_VIDEO_REQUEST = "DELETE_VIDEO_REQUEST";
export const DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS";
export const DELETE_VIDEO_ERROR = "DELETE_VIDEO_ERROR";

export const UPDATE_VIDEO_REQUEST = "UPDATE_VIDEO_REQUEST";
export const UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS";
export const UPDATE_VIDEO_ERROR = "UPDATE_VIDEO_ERROR";

export const CREATE_QUESTION_REQUEST = "CREATE_QUESTION_REQUEST";
export const CREATE_QUESTION_SUCCESS = "CREATE_QUESTION_SUCCESS";
export const CREATE_QUESTION_ERROR = "CREATE_QUESTION_ERROR";

export const GET_CAROUSEL_DATA_REQUEST = "GET_CAROUSEL_DATA_REQUEST";
export const GET_CAROUSEL_DATA_SUCCESS = "GET_CAROUSEL_DATA_SUCCESS";
export const GET_CAROUSEL_DATA_ERROR = "GET_CAROUSEL_DATA_ERROR";

export const GET_VIDEOBANK_REQUEST = "GET_VIDEOBANK_REQUEST";
export const GET_VIDEOBANK_SUCCESS = "GET_VIDEOBANK_SUCCESS";
export const GET_VIDEOBANK_ERROR = "GET_VIDEOBANK_ERROR";

export const ADD_ITEM_VIDEOBANK_REQUEST = "ADD_ITEM_VIDEOBANK_REQUEST";
export const ADD_ITEM_VIDEOBANK_SUCCESS = "ADD_ITEM_VIDEOBANK_SUCCESS";
export const ADD_ITEM_VIDEOBANK_ERROR = "ADD_ITEM_VIDEOBANK_ERROR";

export const DELETE_VIDEOBANK_REQUEST = "DELETE_VIDEOBANK_REQUEST";
export const DELETE_VIDEOBANK_SUCCESS = "DELETE_VIDEOBANK_SUCCESS";

export const CREATE_CAROUSEL_DATA_REQUEST = "CREATE_CAROUSEL_DATA_REQUEST";
export const CREATE_CAROUSEL_DATA_SUCCESS = "CREATE_CAROUSEL_DATA_SUCCESS";
export const CREATE_CAROUSEL_DATA_ERROR = "CREATE_CAROUSEL_DATA_ERROR";

export const UPDATE_CAROUSEL_DATA_REQUEST = "UPDATE_CAROUSEL_DATA_REQUEST";
export const UPDATE_CAROUSEL_DATA_SUCCESS = "UPDATE_CAROUSEL_DATA_SUCCESS";

export const DELETE_CAROUSEL_REQUEST = "DELETE_CAROUSEL_REQUEST";
export const DELETE_CAROUSEL_SUCCESS = "DELETE_CAROUSEL_SUCCESS";

export const DELETE_TRAINER_REQUEST = "DELETE_TRAINER_REQUEST";
export const DELETE_TRAINER_SUCCESS = "DELETE_TRAINER_SUCCESS";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";

export const VIDEO_MODAL_REQUEST = "VIDEO_MODAL_REQUEST";
export const VIDEO_MODAL_SUCCESS = "VIDEO_MODAL_SUCCESS";

export const SELECT_QUESTION_CATEGORY_REQUEST =
  "SELECT_QUESTION_CATEGORY_REQUEST";
export const SELECT_QUESTION_CATEGORY_SUCCESS =
  "SELECT_QUESTION_CATEGORY_SUCCESS";

export const SET_MODALDATA_REQUEST = "SET_MODALDATA_REQUEST";
export const SET_MODALDATAL_SUCCESS = "SET_MODALDATAL_SUCCESS";

export const GENERIC_LOADER_REQUEST = "GENERIC_LOADER_REQUEST";
export const GENERIC_LOADER_SUCCESS = "GENERIC_LOADER_SUCCESS";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_USER_LIST_REQUEST = "GET_USER_LIST_REQUEST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";

export const GET_QUESTION_CATEGORY_REQUEST = "GET_QUESTION_CATEGORY_REQUEST";
export const GET_QUESTION_CATEGORY_SUCCESS = "GET_QUESTION_CATEGORY_SUCCESS";
export const GET_QUESTION_CATEGORY_ERROR = "GET_QUESTION_CATEGORY_ERROR";

export const CREATE_QUESTION_CATEGORY_REQUEST =
  "CREATE_QUESTION_CATEGORY_REQUEST";
export const CREATE_QUESTION_CATEGORY_SUCCESS =
  "CREATE_QUESTION_CATEGORY_SUCCESS";
export const CREATE_QUESTION_CATEGORY_ERROR = "CREATE_QUESTION_CATEGORY_ERROR";

export const UPDATE_QUESTION_REQUEST = "UPDATE_QUESTION_REQUEST";
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS";
export const UPDATE_QUESTION_ERROR = "UPDATE_QUESTION_ERROR";

export const DELETE_QUESTION_CATEGORY_REQUEST =
  "DELETE_QUESTION_CATEGORY_REQUEST";
export const DELETE_QUESTION_CATEGORY_SUCCESS =
  "DELETE_QUESTION_CATEGORY_SUCCESS";

export const UPDATE_QUESTION_CATEGORY_REQUEST =
  "UPDATE_QUESTION_CATEGORY_REQUEST";
export const UPDATE_QUESTION_CATEGORY_SUCCESS =
  "UPDATE_QUESTION_CATEGORY_SUCCESS";

export const UPDATE_USER_SUBSCRIPTION_REQUEST =
  "UPDATE_USER_SUBSCRIPTION_REQUEST";
export const UPDATE_USER_SUBSCRIPTION_SUCCESS =
  "UPDATE_USER_SUBSCRIPTION_SUCCESS";
export const UPDATE_USER_SUBSCRIPTION_ERROR = "UPDATE_USER_SUBSCRIPTION_ERROR";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const OTP_VERIFICATION_REQUEST = "OTP_VERIFICATION_REQUEST";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_ERROR = "OTP_VERIFICATION_ERROR";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_ERROR = "RESEND_OTP_ERROR";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_ERROR = "SEND_NOTIFICATION_ERROR";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_ERROR = "GET_NOTIFICATION_ERROR";

export const SET_PAID_CONTENT_REQUEST = "SET_PAID_CONTENT_REQUEST";
export const SET_PAID_CONTENT_SUCCESS = "SET_PAID_CONTENT_SUCCESS";
export const SET_PAID_CONTENT_ERROR = "SET_PAID_CONTENT_ERROR";

export const UPGRADE_USER_PREMIUM_REQUEST = "UPGRADE_USER_PREMIUM_REQUEST";
export const UPGRADE_USER_PREMIUM_SUCCESS = "UPGRADE_USER_PREMIUM_SUCCESS";
export const UPGRADE_USER_PREMIUM_ERROR = "UPGRADE_USER_PREMIUM_ERROR";

export const UPGRADE_USER_DATA_REQUEST = "UPGRADE_USER_DATA_REQUEST";
export const UPGRADE_USER_DATA_SUCCESS = "UPGRADE_USER_DATA_SUCCESS";
export const UPGRADE_USER_DATA_ERROR = "UPGRADE_USER_DATA_ERROR";

export const GET_BOOKS_REQUEST = "GET_BOOKS_REQUEST";
export const GET_BOOKS_SUCCESS = "GET_BOOKS_SUCCESS";
export const GET_BOOKS_ERROR = "GET_BOOKS_ERROR";

export const POST_BOOKS_REQUEST = "POST_BOOKS_REQUEST";
export const POST_BOOKS_SUCCESS = "POST_BOOKS_SUCCESS";
export const POST_BOOKS_ERROR = "POST_BOOKS_ERROR";

export const PUT_BOOK_REQUEST = "PUT_BOOK_REQUEST";
export const PUT_BOOK_SUCCESS = "PUT_BOOK_SUCCESS";
export const PUT_BOOK_ERROR = "PUT_BOOK_ERROR";

export const DELETE_BOOK_REQUEST = "DELETE_BOOK_REQUEST";
export const DELETE_BOOK_SUCCESS = "DELETE_BOOK_SUCCESS";
export const DELETE_BOOK_ERROR = "DELETE_BOOK_ERROR";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS";
export const CREATE_COUPON_ERROR = "CREATE_COUPON_ERROR";

export const GET_COUPON_LIST_REQUEST = "GET_COUPON_LIST_REQUEST";
export const GET_COUPON_LIST_SUCCESS = "GET_COUPON_LIST_SUCCESS";
export const GET_COUPON_LIST_ERROR = "GET_COUPON_LIST_ERROR";

export const POST_GRANT_PREMIUM_REQUEST = "POST_GRANT_PREMIUM_REQUEST";
export const POST_GRANT_PREMIUM_SUCCESS = "POST_GRANT_PREMIUM_SUCCESS";
export const POST_GRANT_PREMIUM_ERROR = "POST_GRANT_PREMIUM_ERROR";

export const POST_GRANT_PREMIUM_MANY_REQUEST =
  "POST_GRANT_PREMIUM_MANY_REQUEST";
export const POST_GRANT_PREMIUM_MANY_SUCCESS =
  "POST_GRANT_PREMIUM_MANY_SUCCESS";
export const POST_GRANT_PREMIUM_MANY_ERROR = "POST_GRANT_PREMIUM_MANY_ERROR";
