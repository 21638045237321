import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";

const TimerButton = ({ countdownDuration = 60, onResendClick, buttonLabel }) => {
    const [countdown, setCountdown] = useState(countdownDuration);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const startCountdown = () => {
        setIsButtonDisabled(true);
        setCountdown(countdownDuration);

        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(countdownInterval);
            setIsButtonDisabled(false);
        }, countdownDuration * 1000);

        return countdownInterval;
    };

    useEffect(() => {
        const countdownInterval = startCountdown(); // Start countdown initially
        // Cleanup function to clear interval when component unmounts
        return () => clearInterval(countdownInterval);
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const handleButtonClick = () => {
        if (!isButtonDisabled && onResendClick) {
            onResendClick();
            startCountdown();
        }
    };

    return (
        <div>
            <Button onClick={handleButtonClick} disabled={isButtonDisabled} variant="text" color="primary" autoCapitalize="off">
                {buttonLabel} {isButtonDisabled && `in ${countdown} seconds`}
            </Button>
        </div>
    );
};

export default TimerButton;
