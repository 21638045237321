import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import appLogo from '../assets/logo.png'
import {loginRequest, logoutRequest} from '../redux/actions'
import validator from '../utils/validation'
import '../styles/login.scss'
import Loader from '../Components/Loader'
import {Link} from "@material-ui/core";
import fireAjax from "../services";
import {toast} from "react-toastify";

export default function ForgotPassword() {

    const dispatch = useDispatch()
    const history = useHistory();
    const loginData = useSelector(state => state.auth.loginData)
    const [state, setState] = useState({ 
        email: '',
     })

    const handleSubmit = (e) => {
        e.preventDefault()
        const apiResponse = fireAjax("POST", "users/admin/resetPassword",
            undefined, {
                "email": state.email,
            })

        apiResponse.then(response => {
            toast.success(response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            history.push("/")
        }).catch(error => {
            console.log("error", error.response.data.message)
            toast.error(error.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        })
    }

    const handleChange = (value, name) => {
        let data = { ...state }
        data[name] = value
        setState(data)
    }
    
    return (
        <div className="wrapper fadeInDown">
           
            <div id="formContent">
                <h2 className="active"> Reset Password </h2>
                {/* <h2 className="inactive underlineHover">Sign Up </h2> */}

                <form contentEditable={false} onSubmit={handleSubmit}>
                    <input onChange={(e) => handleChange(e.target.value, 'email')} type="text" id="login" className="fadeIn second" name="email" placeholder="Email address" required/>
                    <div style={{ color: 'red', textAlign: 'left', marginLeft: '3rem', fontSize: 15, marginBottom: 10 }} >{loginData.message}</div>
                    <input type="submit" className="fadeIn fourth" value="Submit"/>
                </form>

                <div id="formFooter">
                </div>

            </div>
        </div>
    )
}
