import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import appLogo from '../assets/logo.png'
import { otpVerificationRequest, resendOtpRequest } from '../redux/actions'
import validator from '../utils/validation'
import '../styles/login.scss'
import Loader from '../Components/Loader'
import TimerButton from "../Components/TimerButton";

export default function Otp(props) {

    const dispatch = useDispatch()
    const otpData = useSelector(state => state.auth.otpData)
    const [state, setState] = useState({ 
        otpCode: '',
        errors: {},
        email: props.location.state.email
     })

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = { email: state.email, otpCode: state.otpCode };
        dispatch(otpVerificationRequest(payload));
    }

    const handleChange = (value, name) => {
        let data = { ...state }
        data[name] = value
        setState(data)
    }

    const resendOTP = () => {
        const payload = { email: state.email };
        dispatch(resendOtpRequest(payload));
    }
    
    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <h2 className="active"> Sign In </h2>
                {/* <h2 className="inactive underlineHover">Sign Up </h2> */}

                <div className="fadeIn first">
                    <img src={appLogo} id="icon" alt="User Icon" />
                </div>

                <form contentEditable={false} onSubmit={handleSubmit}>
                    <input onChange={(e) => handleChange(e.target.value, 'otpCode')} type="text" id="otp" className="fadeIn second" name="otpCode" placeholder="Enter the one-time token sent to your email." required/>
                    <div style={{ color: 'red', textAlign: 'left', marginLeft: '3rem', fontSize: 15, marginBottom: 10 }} >{otpData.message}</div>
                    {otpData.isLoading ? <div className='loader-wrapper'><Loader /></div> : '' }
                    <TimerButton buttonLabel="Resend OTP" countdownDuration={60} onResendClick={resendOTP} />
                    <input type="submit" className="fadeIn fourth" value="Submit"/>
                </form>

                <div id="formFooter">
                </div>

            </div>
        </div>
    )
}
