import React, { forwardRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { setModalDataRequest, videoModalRequest } from "../redux/actions";
import Loader from "./Loader";
import { ReactComponent as UpgradeIcon } from "../assets/svgs/upgrade.svg";
import { Button, Checkbox } from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerText: {
    fontWeight: "700",
  },
  deleteIcon: {
    cursor: "pointer",
  },
  tableRow: {
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  tableLoader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  selectedActionButton: {
    fontWeight: "bold",
    fontSize: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: 10,
  },
  iconButton: {
    paddingRight: 4,
  },
  downgradeAllButton: {
    backgroundColor: "#757575",
    marginRight: 10,
  },
});

const GenericTable = (
  {
    headers = [],
    cellData,
    keys = [],
    handleDelete = () => null,
    deleteIcon = true,
    editIcon = true,
    editIconComponent,
    headerAlign = "right",
    tableCellAlign = "right",
    multipleSelect = false,
    downgradeAllAction,
    handleDowngradeAllAction,
    deleteAllAction,
    handleDeleteAllAction,
    upgradeAllAction,
    handleUpgradeAllAction,
  },
  ref
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [itemToDelete, setItemToDelete] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loadedTableData, setLoadedTableData] = useState(cellData.slice(0, 20));
  const [itemSelectedList, setItemSelectedList] = useState([]);
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);

  const handleLoadMore = () => {
    setTimeout(() => {
      const newData = cellData.slice(page * 20, (page + 1) * 20);
      setLoadedTableData((prevData) => [...prevData, ...newData]);
      setPage(page + 1);
      if ((page + 1) * 20 >= cellData.length) {
        setHasMore(false);
      }
    }, 500);
  };

  const isLoading = useSelector((state) => state.config.isLoading);

  const handleEdit = (item) => {
    dispatch(setModalDataRequest(item));
    dispatch(videoModalRequest(true));
  };

  const getRowItem = (row, item) => {
    // if(item === 'description' || item === 'about'){
    //   return <div style={{width:100, height:100, overflow:'hidden'}} >{row[item]}</div>
    // }
    return row[item];
  };

  const getEditComponet = (row) => {
    return (
      editIconComponent || (
        <CreateIcon
          onClick={() => {
            handleEdit(row);
          }}
          className={classes.deleteIcon}
        />
      )
    );
  };

  const handleSelectMasterCheckBox = (value) => {
    //change property isChecked in checkbox UI
    setLoadedTableData(
      loadedTableData.map((data) => ({ ...data, isChecked: value }))
    );
    //add itemSelectedList to return
    setItemSelectedList(
      value
        ? [...loadedTableData.map((data) => ({ ...data, isChecked: value }))]
        : []
    );
    // setMasterCheckboxChecked(true);
  };

  const handleSelectCheckBox = (value) => {
    //add itemSelectedList to return
    const newItemSelectedList = itemSelectedList.some(
      (item) => item._id === value._id
    )
      ? itemSelectedList.filter((item) => item._id !== value._id)
      : [...itemSelectedList, value];

    setItemSelectedList(newItemSelectedList);
    //change property isChecked in checkbox UI
    setLoadedTableData(
      loadedTableData.map((data) =>
        data._id === value._id ? { ...data, isChecked: !value.isChecked } : data
      )
    );
  };

  React.useEffect(() => {
    Array.isArray(cellData) && setLoadedTableData(cellData.slice(0, 20));
    handleLoadMore();
  }, [cellData]);

  React.useEffect(() => {
    if (itemSelectedList.length === cellData.length) {
      setMasterCheckboxChecked(true);
    } else {
      setMasterCheckboxChecked(false);
    }
  }, [itemSelectedList]);

  React.useImperativeHandle(ref, () => ({
    changeItemSelectedList(newValue) {
      setItemSelectedList(newValue);
    },
  }));

  return (
    <>
      {(downgradeAllAction || deleteAllAction || upgradeAllAction) && (
        <div className={classes.selectedActionButton}>
          {downgradeAllAction && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDowngradeAllAction(itemSelectedList)}
              className={classes.downgradeAllButton}
              disabled={itemSelectedList.length === 0}
            >
              <ArrowDownwardIcon className={classes.iconButton} />
              Downgrade all
            </Button>
          )}
          {deleteAllAction && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDeleteAllAction(itemSelectedList)}
              disabled={itemSelectedList.length === 0}
            >
              <DeleteIcon className={classes.iconButton} />
              Delete all
            </Button>
          )}
          {upgradeAllAction && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUpgradeAllAction(itemSelectedList)}
              disabled={itemSelectedList.length === 0}
            >
              <ArrowUpwardIcon className={classes.iconButton} />
              Upgrade all
            </Button>
          )}
        </div>
      )}

      <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={hasMore}
        loader={
          <div className={classes.tableLoader}>
            <Loader key={0} variant="small" />
          </div>
        }
        initialLoad={false}
      >
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {multipleSelect && (
                  <TableCell
                    key={`master-checkbox-cell`}
                    className={classes.headerText}
                    component="th"
                    scope="row"
                  >
                    <Checkbox
                      onClick={(e) =>
                        handleSelectMasterCheckBox(e.target.checked)
                      }
                      checked={masterCheckboxChecked}
                      name="master-checkbox"
                      color={"green"}
                    />
                  </TableCell>
                )}
                {headers.map((headerTitle, index) => {
                  if (index === 0) {
                    return (
                      <TableCell
                        key={`${headerTitle}-${index}`}
                        className={classes.headerText}
                        component="th"
                        scope="row"
                      >
                        {headerTitle}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={`${headerTitle}-${index}`}
                      className={classes.headerText}
                      align={headerAlign}
                    >
                      {headerTitle}
                    </TableCell>
                  );
                })}
                {editIcon && (
                  <TableCell className={classes.headerText} align="right">
                    {"Edit"}
                  </TableCell>
                )}
                {deleteIcon && (
                  <TableCell className={classes.headerText} align="right">
                    {"Delete"}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loadedTableData.map((row, i) => {
                return (
                  <TableRow className={classes.tableRow} key={i}>
                    {multipleSelect && (
                      <TableCell key={`${row[i]}`} component="th" scope="row">
                        <Checkbox
                          onClick={() => handleSelectCheckBox(row)}
                          checked={row.isChecked}
                          color={"green"}
                        />
                      </TableCell>
                    )}
                    {keys.map((item, index) => {
                      if (index === 0) {
                        return (
                          <TableCell
                            key={`${index}`}
                            component="th"
                            scope="row"
                          >
                            {row[item]}{" "}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={`${index}`} align={tableCellAlign}>
                          {getRowItem(row, item)}
                        </TableCell>
                      );
                    })}
                    {editIcon && (
                      <TableCell align="right">
                        {getEditComponet(row)}
                      </TableCell>
                    )}
                    {deleteIcon && (
                      <TableCell align="right">
                        {itemToDelete === row._id && isLoading ? (
                          <Loader variant="small" />
                        ) : (
                          <DeleteIcon
                            onClick={() => {
                              setItemToDelete(row._id);
                              handleDelete(row._id);
                            }}
                            className={classes.deleteIcon}
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </>
  );
};
export default forwardRef(GenericTable);
