import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteBookRequest,
  getBooksRequest,
  postBooksRequest,
} from "../redux/actions";
import AddIcon from "@material-ui/icons/Add";
import GenericTable from "../Components/Table";
import { Fab } from "@material-ui/core";
import UploadPdfModal from "../Components/UploadPDFModal";

export const Pdf = () => {
  const classes = useStyles();
  const [uploadedBookData, setUploadedBookData] = useState({
    title: "",
    description: "",
    book: null,
    image: null,
  });
  const [showUploadPDFModal, setShowUploadPDFModal] = useState(false);
  const acceptedFiles = ["application/pdf"];
  const dispatch = useDispatch();

  const getBooks = useSelector((state) => state.books.getBooks);

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (acceptedFiles.includes(file.type)) {
      return setUploadedBookData({
        ...uploadedBookData,
        book: file,
      });
    }
    toast.error("Incorrect format. Add a file in .pdf format ", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setUploadedBookData({
      ...uploadedBookData,
      book: file,
    });
  };

  const handleFileImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const url = event.target.result;
      setUploadedBookData({
        ...uploadedBookData,
        file,
        url,
      });
    };
    reader.readAsDataURL(file);
  };

  const handleBookUpload = () => {
    const formData = new FormData();
    formData.append("title", uploadedBookData.title);
    formData.append("description", uploadedBookData.title);
    formData.append("addToHeroCarousel", true);
    formData.append("book", uploadedBookData.book);
    formData.append("image", uploadedBookData.file);
    dispatch(postBooksRequest(formData));
    handleToggleModal();
    toast.success("Book Uploaded", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleToggleModal = () => {
    setShowUploadPDFModal(!showUploadPDFModal);
    setUploadedBookData({
      title: "",
      description: "",
      book: null,
      image: null,
    });
  };

  const makeTableData = (rows = []) => {
    if (!rows.length) {
      return [];
    }
    let data = [];
    rows.map((item) => {
      let a = {
        image: (
          <img src={item.image} className={classes.avtar} alt={item.title} />
        ),
        title: item.title,
        description: item.description,
        _id: item._id,
      };
      data.push(a);
    });
    return data;
  };

  const handleDelete = (_id) => {
    dispatch(deleteBookRequest(_id));
    toast.success("Deleted", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  React.useEffect(() => {
    dispatch(getBooksRequest());
  }, [dispatch]);

  return (
    <div className={classes.container}>
      <div style={{ width: "100%" }}>
        {getBooks.isSuccess && (
          <GenericTable
            headers={["Image", "Title", "Description"]}
            cellData={makeTableData(getBooks?.data)}
            keys={["image", "title", "description"]}
            deleteIcon={true}
            editIcon={false}
            handleDelete={handleDelete}
            headerAlign="center"
            tableCellAlign="right"
          />
        )}
      </div>
      <UploadPdfModal
        showUploadPDFModal={showUploadPDFModal}
        handleToggleModal={handleToggleModal}
        uploadedBookData={uploadedBookData}
        handleFileDrop={handleFileDrop}
        handleFileInputChange={handleFileInputChange}
        handleFileImageUpload={handleFileImageUpload}
        handleBookUpload={handleBookUpload}
        setUploadedBookData={setUploadedBookData}
      />
      <Fab
        size="small"
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={handleToggleModal}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(5),
    background: "#3ea8ad",
  },
  avtar: {
    width: 80,
    height: 50,
    borderRadius: 5,
  },
}));
