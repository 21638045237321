import ChangePassword from "../Components/ChangePassword";
import {useDispatch, useSelector} from "react-redux";
import fireAjax from "../services";
import {toast} from 'react-toastify';
import {logoutRequest} from "../redux/actions";
import history from "../History";
import {useHistory} from "react-router-dom";
import {useState} from "react";

export default function ForceChangePassword(props) {

    const dispatch = useDispatch()
    const history = useHistory();
    const loginData = useSelector(state => state.auth.loginData)

    const [state, setState] = useState({
        email: props.location.state.email
    })

    const onSubmitHandler = (currentPassword, newPassword, newPasswordConfirmation) => {
        const apiResponse = fireAjax("POST", "users/admin/changePassword",
            undefined, {
                "email": state.email,
                currentPassword,
                newPassword,
                newPasswordConfirmation,
                loginFlow: true,
            })

        apiResponse.then(response => {
            toast.success(response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            history.push("/otp", {
                email: state.email
            })
        }).catch(error => {
            console.log("error", error.response.data.message)
            toast.error(error.response.data.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        })
    }

    return (
        <ChangePassword onSubmit={onSubmitHandler}/>
    )
}