import { handleActions } from "redux-actions";
import update from "immutability-helper";
import * as constants from "../constants";
const initialState = {
  systemStatus: {
    isLoading: false,
    errorMessage: []
  },
};

//functions to update user subscription
const onDataLoading = (state, action) => {
  return update(state, {
    systemStatus: {
      isLoading: { $set: true },
    }
  });
};

const onDataLoadedSuccess = (state, action) => {
  return update(state, {
    systemStatus: {
      isLoading: { $set: false },
    }
  });
};

const onDataLoadedError = (state, action) => {
  return update(state, {
    systemStatus: {
      isLoading: { $set: false },
      errorMessage:{$set:action.payload.message || ''},
    }
  });
};

export default handleActions(
  {
    [constants.SYSTEM_DATA_LOADING_REQUEST]: onDataLoading,
    [constants.SYSTEM_DATA_LOADED_SUCCESS]: onDataLoadedSuccess,
    [constants.SYSTEM_DATA_LOADED_ERROR]: onDataLoadedError,
  },
  initialState
);
