import * as actions from "../actions";
import fireAjax from "../../services";
import { call, put } from "redux-saga/effects";

export function* getBooksRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", "books", "");

    if (response.data) {
      yield put(actions.getBooksSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getBooksError({ message: error.message }));
  }
}

export function* postBooksRequest(action) {
  try {
    const response = yield call(fireAjax, "POST", "books", "", action.payload);
    const bookData = yield call(fireAjax, "GET", "books", "");

    if (response.data) {
      yield put(actions.postBooksSuccess(response.data));
      yield put(actions.getBooksSuccess(bookData.data));
    }
  } catch (error) {
    yield put(actions.postBooksError({ message: error.message }));
  }
}

export function* putBookRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `books/${action.payload.id}`,
      "",
      action.payload.data
    );

    if (response.data) {
      yield put(actions.putBookSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.putBookError({ message: error.message }));
  }
}

export function* deleteBookRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "DELETE",
      `books/${action.payload}`,
      ""
    );
    const bookData = yield call(fireAjax, "GET", "books", "");

    if (response.data) {
      yield put(actions.deleteBookSuccess(response.data));
      yield put(actions.getBooksSuccess(bookData.data));
    }
  } catch (error) {
    yield put(actions.deleteBookError({ message: error.message }));
  }
}
