import * as actions from "../actions";
import fireAjax from "../../services";
import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import Error from "../../Components/Error";
import { toast } from "react-toastify";

toast.configure();

export const LOCAL_STORAGE_CURRENT_USER = "currentUser";

//function to update user subscription
export function* updateUserSubscriptionRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "PUT",
      `users/updateProfile/${action.payload.userId}`,
      "",
      { ...action.payload }
    );
    yield put(actions.updateUserSubscriptionSuccess({ ...response.data }));
  } catch (e) {
    yield put(actions.updateUserSubscriptionError({ message: e.message }));
  }
}

//login
export function* loginRequest(action) {
  const headers = {
    loginType: "admin",
  };
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `users/admin/login`,
      headers,
      { ...action.payload }
    );

    yield put(actions.loginSuccess({ ...response.data }));

    const data = response.data;
    yield put(
      push(data.nextLoginFlow, {
        email: action.payload.email,
      })
    );
  } catch (e) {
    yield put(
      actions.loginError({ message: e?.response?.data?.message || e?.message })
    );
  }
}

export function* logoutRequest(action) {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER);
    yield put(push("/"));
  } catch (error) {
    console.log(error);
  }
}

//OTP verification
export function* otpVerificationRequest(action) {
  try {
    const headers = {
      loginType: "admin",
    };

    const response = yield call(
      fireAjax,
      "POST",
      `users/admin/verifyOtp`,
      headers,
      { ...action.payload }
    );

    console.log("response", response);
    yield put(actions.otpVerificationSuccess({ ...response.data }));
    const currentUserData = {
      ...response.data.data,
      authenticated: true,
    };
    window.localStorage.setItem(
      LOCAL_STORAGE_CURRENT_USER,
      JSON.stringify(currentUserData)
    );
    yield put(
      actions.setCurrentUser({
        currentUserData,
      })
    );

    yield put(push("/"));
  } catch (e) {
    yield put(
      actions.otpVerificationError({
        message: e?.response?.data?.message || e?.message,
      })
    );
  }
}

// Resend OTP
export function* resendOtpRequest(action) {
  try {
    const headers = {
      loginType: "admin",
    };

    const response = yield call(
      fireAjax,
      "POST",
      `users/admin/resendOtp`,
      headers,
      { ...action.payload }
    );

    yield put(actions.resendOtpSuccess({ ...response.data }));
  } catch (e) {
    yield put(
      actions.otpVerificationError({
        message: e?.response?.data?.message || e?.message,
      })
    );
  }
}

//function to update user subscription
export function* getDashboardRequest(action) {
  try {
    const response = yield call(fireAjax, "GET", `users/dashboard`, "", {
      ...action.payload,
    });
    yield put(actions.getDashboardSuccess({ ...response.data }));
  } catch (e) {
    yield put(
      actions.getDashboardError({
        message: e?.response?.data?.message || e?.message,
      })
    );
  }
}

export function* sendNotificationRequest(action) {
  try {
    const response = yield call(
      fireAjax,
      "POST",
      `users/sendNotification`,
      "",
      action.payload
    );
    if (response.data.message === "device token not available!") {
      throw new Error(response.data.message);
    }
    yield put(actions.sendNotificationSuccess({ ...response.data }));

    toast.success("Notification Send Successfully", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } catch (e) {
    // alert( e?.response?.data?.message || e?.message)
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(
      actions.sendNotificationError({
        message: e?.response?.data?.message || e?.message,
      })
    );
  }
}

export function* getNotificationRequest(action) {
  console.log("sssssssssssss");

  try {
    const response = yield call(
      fireAjax,
      "GET",
      `users/admin/listNotifications`,
      "",
      action.payload
    );
    if (response.data.message === "device token not available!") {
      throw new Error(response.data.message);
    }
    yield put(actions.getNotificationSuccess({ ...response.data }));
  } catch (e) {
    // alert( e?.response?.data?.message || e?.message)

    yield put(
      actions.getNotificationError({
        message: e?.response?.data?.message || e?.message,
      })
    );
  }
}
