import {createStore, applyMiddleware} from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import logger from 'redux-logger';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import history from '../History';

const reduxRouterMiddleware = routerMiddleware(history)

const middleware = [];
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

//logger to log the firing actions
middleware.push(reduxRouterMiddleware)
middleware.push(sagaMiddleware);
if (process.env.NODE_ENV !== "production") {
    middleware.push(logger);
}

// mount it on the Store
const store = createStore(rootReducer(history), applyMiddleware(...middleware));

// then run the saga
sagaMiddleware.run(rootSaga);
// render the application

export default store;


