import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import moment from "moment";
import { Backdrop, Button, Fade, TextField } from "@material-ui/core";
import { postGrantPremiumManyRequest } from "../redux/actions";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    height: "20vh",
    width: "50vh",
    borderRadius: 5,
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid rgba(0,0,0, 0.2)",
  },
  closeIcon: {
    cursor: "pointer",
  },
  content: {
    padding: 16,
    // overflowY: "scroll",
  },
  footer: {
    padding: theme.spacing(1),
    borderTop: "1px solid rgba(0,0,0, 0.2)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    outline: "none",
    border: "none",
    background: "#065fd4",
    color: "#fff",
    width: 100,
    height: 40,
    cursor: "pointer",
  },
  selectWrapper: {
    display: "flex",
    alignItems: "center",
    paddingTop: 2,
  },
  videoBankTypeWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  label: {
    marginRight: 5,
  },
}));

// const initialState = {
//   premiumValidTillId: moment(new Date()).format("YYYY-MM-DD"),
// };

export default function GrantPremiumModal({
  primaryText,
  secondaryText,
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  data,
  open,
  handleClose,
  searchData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.config.isLoading);
  const [validTill, setValidTill] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [isEdited, setIsEdited] = useState(false);

  const today = new Date().toISOString().split("T")[0];

  //   const handleClose = () => {
  //     dispatch(videoModalRequest(false));
  //     handleReset();
  //   };

  const handleGrantPremium = () => {
    const payload = {
      data: {
        userIds: data.map((user) => user._id),
        end_date: validTill,
      },
      search_data: searchData,
    };
    console.log(payload);

    dispatch(postGrantPremiumManyRequest(payload));
    handleReset();
  };

  const handleChange = (evt) => {
    setIsEdited(true);
    setValidTill(moment(new Date(evt.target.value)).format("YYYY-MM-DD"));
  };

  const handleReset = () => {
    setValidTill(moment(new Date()).format("YYYY-MM-DD"));
    setIsEdited(false);

    handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleReset}
      //   onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <span>Grant Premium</span>
            <CloseIcon onClick={handleClose} className={classes.closeIcon} />
          </div>
          <div className={classes.content}>
            <div className={classes.selectWrapper}>
              <div className={classes.label}>Valid till:</div>
              <TextField
                id="premiumValidTillId"
                // label="Valid till"
                type="date"
                name="premiumValidTillId"
                // defaultValue={premiumValidTo.current.value}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: today,
                }}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={classes.footer}>
            <Button
              disabled={!isEdited}
              onClick={handleGrantPremium}
              variant="contained"
              className={classes.button}
            >
              {isLoading ? <Loader style={{ color: "#fff" }} /> : "Submit"}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
